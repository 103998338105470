.root
  display: flex
  flex-direction: column
  padding: 12px 16px

.content
  // Hack for slick-slider
  min-width: 0
  flex-grow: 1

.breadcrumbPlaceholder
  // Important for CLS web-vital on mobile
  min-height: 24px
  width: 100%

.mobileSortingFiltering
  display: flex
  gap: 8px
  margin: 8px 0 16px 0

  .buttonFilter, .sort
    width: 50%

.filterButtonIcon
  width: 24px
  height: 24px
  fill: #414141
  vertical-align: middle

.desktopSortWrapper
  display: none

.savedSearchTopMobileButtons.savedSearchTopMobileButtons
  margin-bottom: 16px

.banner.banner
  //  TODO(a.kravchuk): remove padding from original component
  padding-left: 0
  margin-bottom: 24px

.titleBlock
  display: flex
  margin-bottom: 16px

.title.title
  font-size: 24px

.titleCount
  font-size: 20px
  color: $brownish-grey
  display: inline
  margin-left: 6px

.topLevelCategories
  margin-bottom: 16px

.activeFilters
  display: none
  margin-top: 32px

.products
  margin-top: 24px
  display: flex
  flex-direction: column
  align-items: center

.grid
  width: 100%

.topProductsMargin
  margin-left: 10px
  margin-right: 10px

.loading
  opacity: .5
  transition: opacity .3s ease-in

.pagination
  width: fit-content
  margin: 0 auto

@media ($min-desktop)

  .root
    padding: 24px 0 32px 0
    flex-direction: row
    gap: 40px

  .mobileSortingFiltering
    display: none

  .savedSearchTopMobileButtons.savedSearchTopMobileButtons
    display: none

  .desktopSortWrapper
    display: flex
    justify-content: space-between
    align-items: center
    margin: 24px 0

  // TODO(a.kravchuk): remove margin from original component
  .savedSearchTopDesktopButtons.savedSearchTopDesktopButtons
    margin: 0

  .activeFilters
    display: block

  .products
    margin-top: 8px
